import React,{useEffect} from 'react';
import { NavLink} from "react-router-dom";
import $ from 'jquery';


const Header = () => {

    useEffect(() => {
    
    // const scrollto = (el) => {
    //     let offset = Math.round($('#header').outerHeight());
    //     if (!$('#header').toggleClass('header-scrolled')) {
    //       offset -= 16
    //     }
    
    //     let elementPos = ($(el).offset()).top;

    //     window.scrollTo({
    //       top: elementPos - offset,
    //       behavior: 'smooth'
    //     })
    //   }

    // $('.scrollto').on('click',(e)=>{
    //     if ($(this.hash)) {
    //         e.preventDefault();
    //         if ($('#navbar').hasClass('navbar-mobile')) {
    //             $('#navbar').removeClass('navbar-mobile');
    //             $('.mobile-nav-toggle').toggleClass('bi-list');
    //             $('.mobile-nav-toggle').toggleClass('bi-x');

    //         }
    //     }
    //     scrollto(this.hash);
    // });

    $('.mobile-nav-toggle').each(function () {
        $(this).on('click', function ()  {
            $('#navbar').toggleClass('navbar-mobile');
            $(this).toggleClass('bi-list');
            $(this).toggleClass('bi-x'); 
        });
        
    });

    $('.navbar .dropdown span').on('click', function (e)  {
        e.preventDefault();
        if ($('#navbar').hasClass('navbar-mobile')) {
            $(this).next().toggleClass('dropdown-active');
        } 
    });

    }, []);


    return (
        <header id="header" className="d-flex align-items-center">
                <div className="container d-flex align-items-center">

                    <h1 className="logo me-auto">
                        <NavLink to="/">Hi-Tech Vision</NavLink>
                        <p className="sub">Votre solution informatique</p>
                        </h1>
                    <nav id="navbar" className="navbar">
                    <ul>
                        {/* active */}
                        <li><NavLink className="nav-link scrollto" to="/">Accueil</NavLink></li>
                        <li><NavLink className="nav-link scrollto" to="/a_propos">A Propos</NavLink></li>
                        <li className="dropdown" id='dropdown'><span><a href="#"><span>Services</span> <i className="bi bi-chevron-down"></i></a></span>
                        <ul id='ul'>
                            <li className="dropdown" to="#"><span><a href="#"><span>Développement et Création Web</span> <i className="bi bi-chevron-right"></i></a></span>
                                <ul>
                                    <li><NavLink to="/site_internet">Site Internet</NavLink></li>
                                    <li><NavLink to="/application_web_&_mobile">Application Web & Mobile</NavLink></li>
                                    {/* <li><NavLink to="/logiciel">Logiciel</NavLink></li> */}
                                </ul>
                            </li>
                            <li><NavLink to="/marketing_digital">Marketing Digital</NavLink></li>
                            <li><NavLink to="/maintenance_informatique">Maintenance Informatique</NavLink></li>
                            <li className="dropdown" to="#"><span><a href="#"><span>Réseau et Sécuruté Informatique</span> <i className="bi bi-chevron-right"></i></a></span>
                                <ul>
                                    <li><NavLink to="/telephonie_sur_ip">Téléphonie Sur Ip</NavLink></li>
                                    <li><NavLink to="/call_center">Call Center</NavLink></li>
                                    <li><NavLink to="/video_surveillance">Vidéo Surveillance</NavLink></li>
                                    <li><NavLink to="/administration_reseau">Administration Réseau</NavLink></li>
                                </ul>
                            </li>
                            <li><NavLink to="/hebergement_web">Hébergement Web</NavLink></li>
                            <li><NavLink to="/conception_graphique">Conception Graphique</NavLink></li>
                            <li><NavLink to="/formations">Formations</NavLink></li>
                        </ul>
                        </li>
                        <li><NavLink className="nav-link scrollto" to="/solutions" >Solutions</NavLink></li>
                        <li><NavLink className="nav-link scrollto" to="/realisations" >Réalisations</NavLink></li>
                        <li><NavLink className="nav-link scrollto" to="/contact" >Contact</NavLink></li>
                        <li><NavLink className="getstarted scrollto" to="/lancer_votre_projet" >Lancer Votre Projet</NavLink></li>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle"></i>
                    </nav>

                </div>
            </header>
    );
};

export default Header;

