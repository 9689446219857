import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

export default function notFound(){
    // const h1 = {
    //     color: 'blue'
    // };
    return (
        <>
            <Header/>
            <main> 
                <div className='text-center animate__animated animate__zoomIn'>
                    <img style={{width:'37%',marginTop:'10px'}} src="assets/img/404.png" alt='404'/>
                </div>
            </main>
            <Footer/>
        </>
    )
}