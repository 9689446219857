import React from "react";
import {NavLink} from "react-router-dom";

class Valeurs extends React.Component{
    render(){
        return(
            <section id="featured-services" className="featured-services section-bg">
            <div className="container">

                <div className="row no-gutters">
                <div className="col-lg-4 col-md-6 animate__animated animate__fadeInLeft">
                    <div className="icon-box">
                    <div className="icon"><i className="bi bi-laptop"></i></div>
                    <h4 className="title"><NavLink to="">Qualité</NavLink></h4>
                    <p className="description">L'ensemble des propriétés et caractéristiques d'un service ou d'un produit qui lui confèrent l'aptitude à satisfaire des besoins exprimés ou implicites. Elle exprime la recherche de l'excellence.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 animate__animated animate__fadeInDown">
                    <div className="icon-box">
                    <div className="icon"><i className="bi bi-briefcase"></i></div>
                    <h4 className="title"><NavLink to="">Coût</NavLink></h4>
                    <p className="description">Un coût est la mesure d'une consommation exprimée en valeur monétaire. Nous nous éfforçons toujours de rendre à nos clients des services de qualité tout en minimisant les coûts.</p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 animate__animated animate__fadeInRight">
                    <div className="icon-box">
                    <div className="icon"><i className="bi bi-calendar4-week"></i></div>
                    <h4 className="title"><NavLink to="">Délai</NavLink></h4>
                    <p className="description">C'est une limite de temps, une échéance, par laquelle un objectif ou une tâche doit être accompli. A <strong><NavLink to="/">Hi-Tech Vision</NavLink></strong>, nous faisons toujours notre maximum pour que ce temps soit le plus court possible.</p>
                    </div>
                </div>
                </div>
            </div>
        </section>
        )
    }
}

export default Valeurs;