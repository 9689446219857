import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Ctf from "../../components/Ctf";
import Althero from "../../components/Althero";
// import FaqConceptionGraphique from "../../components/FaqConceptionGraphique";


export default function ConceptionGraphique(){
    return(
        <>
            <Header/>
            <Althero text='Conception Graphique' imgUrl='assets/img/cg.jpg'/>            

            <section id="about" className="about">
                <div className="container">

                    <div className="section-title">
                        <h2 style={{ color:'var(--primary',textTransform:'capitalize'}}>Création graphique et identité visuelle</h2>
                    </div>

                    <div className="row">
                    <div className="col-lg-6 order-1 order-lg-2">
                        <img src={'assets/img/cg2.jpg'} className="img-fluid animate__animated animate__zoomIn" alt=""/>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                        {/* <h4> <strong><a href="/">Hi-Tech Vision</a></strong></h4> */}
                        <br/>
                        <p className="fst-italic line">
                            La <strong>conception graphique</strong> est la représentation d'un concept à travers des images et des mots qui constituent un message visuel. 
                            Une image vaut mille mots, et c'est une réalité, car où que nous allions ou regardions, nous sommes exposés à des images et des mots qui communiquent quelque chose de spécifique.
                            Le concept graphique se reflète dans les produits visuels et est d'une grande importance pour définir une marque, l'image d'une entreprise ou d'une institution; ceci passant par des<strong> logos, flyers, carte de visite , roll-up</strong> etc...
                            A <strong><a href="/">Hi-Tech Vision</a></strong>, nous assurerons le rehaussement visuel de votre marque grâce à notre panel de spécialistes qui pourrront vous accompagner dans vos idéaux. 

                        </p>
                    </div>
                    </div>

                </div>
            </section>

            {/* <FaqConceptionGraphique/> */}


            <Ctf/>

            <Footer/>
        
        </>
    )
}