import React from "react";
// import TypedComponent from "./TypedComponent";

class Althero extends React.Component{

    render(){

      return(
            <section id="althero" className="animate__animated animate__fadeIn">
            <div id="heroCarousel" data-bs-interval="5000" className="carousel slide carousel-fade" data-bs-ride="carousel">

              <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

              <div className="carousel-inner" role="listbox">

                <div className="carousel-item active" style={ {backgroundImage: 'url('+this.props.imgUrl+')'}}> 
                  <div className="carousel-container">
                    <div className="container">
                      <div>
                        <h2 className="animate__animated animate__bounce "><span className="animate__animated animate__flip">{this.props.text}</span></h2>
                      </div>
                      {/* <p className="animate__animated animate__fadeInUp">Votre <TypedComponent typedContent={ ['agence digitale...','hébergeur web...','solution informatique...'] } backSpeed={45} loop={false}/></p> */}
                      {/* <br/>
                      <p className='animate__animated animate__fadeInDown'>
                        Nous Sommes une agence digitale  spécialisée dans la conception et mise en place de solution informatique situer à quelque mètres de la pharmacie Cosmos (entre BP-CITE et Ndokotti)  dans la ville de Douala.
                      </p> */}
                      {/* <a href="#/a_propos" className="btn-get-started animate__animated animate__fadeInUp scrollto">En Savoir Plus</a> */}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
      )
        
    }
}

export default Althero;