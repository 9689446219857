import React from 'react';
import './App.css';
import ReactDOM from 'react-dom/client';
import App from './pages/App';
import Apropos from './pages/Apropos';
import NotFound from './pages/NotFound';
import Contact from './pages/Contact';
import Solutions from './pages/Solutions';
import Projet from './pages/Projet';

import Description from './pages/description/Description';


import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import des services
import HebergementWeb from './pages/services/HebergementWeb';
import Application from './pages/services/Application';
import SiteInternet from './pages/services/SiteInternet';
import MarketingDigital from './pages/services/MarketingDigital';
import MaintenanceInfo from './pages/services/MaintenanceInfo';
import TelephonieIp from './pages/services/TelephonieIp';
import VideoSurveillance from './pages/services/VideoSurveillance';
import AdministrationReseau from './pages/services/AdministrationReseau';
import Formation from './pages/services/Formation';
import ConceptionGraphique from './pages/services/ConceptionGraphique';
import Realisations from './pages/Realisations';
import CallCenter from './pages/services/CallCenter';




export default function Navigate() {

    
    return (
            <Router>
                <Routes>
                    <Route exact path="/" element={<App />} />
                    <Route exact path="/a_propos" element={<Apropos />} />
                    <Route exact path="/contact" element={<Contact />} />
                    

                    <Route exact path="/solutions" element={<Solutions />} />

                        {/* ROUTES DES DIFFERENTES SOLUTIONS */}

                        <Route exact path="/solutions/esm" element={
                            <Description 
                                name="Easy School Manager"
                                imgUrl="../assets/img/box_esm.jpg"
                                category="Application Web"
                                dateMaj="08 Fevrier 2023"
                                solutionUrl="http://scolarite.hi-techvisioncm.com/Scolarite/"
                                description="Easy School Manager en abrégé ESM est logiciel destiné à la gestion de la scolarite de different etablissement. 
                                            il permet notament l'enregistrement des etudiants et le suivie en temps réel de leur paiements et bien evidenment 
                                            de leurs solvabilités."
                            />} 
                        />

                        <Route exact path="/solutions/vision_school" element={
                            <Description 
                                name="Vision School"
                                imgUrl="../assets/img/box_vs.jpg"
                                category="Application Web"
                                dateMaj=""
                                solutionUrl="http://scolarite.hi-techvisioncm.com/s-tools/public/"
                                description="Vision School est un Progiciel de gestion d'etablissements en general qui comporte en tout 3 module. 
                                    le premier pour la gestion des emplois de temps, le deuxième pour la gestion des notes et le troisième pour la gestion de la paie."
                            />} 
                        />

                        <Route exact path="/solutions/hi_stock" element={
                            <Description 
                                name="Hi-Stock"
                                imgUrl="../assets/img/box_hiStock.jpg"
                                category="Application Web"
                                dateMaj=""
                                solutionUrl="http://scolarite.hi-techvisioncm.com/Hi-Stock/public/"
                                description="Hi-Stock est un logiciel ideale pour la gestion de stock allant de la gestion des besoins en termes de produits 
                                jusqu'aux etats de stock, vous disposez d'un puissant atout d'aide à la decision quant l'approvisionement de votre stock." 
                                
                            />
                            } 
                        />

                        
                        <Route exact path="/solutions/test_generator_solution" element={
                            <Description 
                                name="Test Generator Solution"
                                imgUrl="../assets/img/"
                                category="Application Web"
                                dateMaj=""
                                solutionUrl="/"
                                description="Test Generator Solution lorem Ipsum Dolor...." 
                                
                            />
                            } 
                        />


                        <Route exact path="/solutions/vision_ebook" element={
                            <Description 
                                name="Vision E-book"
                                imgUrl="../assets/img/"
                                category="Application Web"
                                dateMaj=""
                                solutionUrl="/"
                                description="Vision E-book lorem Ipsum Dolor...." 
                                
                            />
                            } 
                        />

                        <Route exact path="/solutions/vision_travel_manager" element={
                            <Description 
                                name="Vision Travel Manager"
                                imgUrl="../assets/img/"
                                category="Application Web"
                                dateMaj=""
                                solutionUrl="/"
                                description="Vision Travel Manager lorem Ipsum Dolor...." 
                                
                            />
                            } 
                        />

                    <Route/>


                    <Route exact path="/realisations" element={<Realisations />} />
                    <Route exact path="/lancer_votre_projet" element={<Projet />} />



                        {/* SERVICES */}

                    <Route exact path="/application_web_&_mobile" element={<Application />} />
                    <Route exact path="/site_internet" element={<SiteInternet />} />
                    <Route exact path="/marketing_digital" element={<MarketingDigital />} />
                    <Route exact path="/maintenance_informatique" element={<MaintenanceInfo />} />
                    <Route exact path="/telephonie_sur_ip" element={<TelephonieIp />} />
                    <Route exact path="/call_center" element={<CallCenter />} />
                    <Route exact path="/video_surveillance" element={<VideoSurveillance />} />
                    <Route exact path="/administration_reseau" element={<AdministrationReseau />} />
                    <Route exact path="/hebergement_web" element={<HebergementWeb />} />
                    <Route exact path="/conception_graphique" element={<ConceptionGraphique />} />
                    <Route exact path="/formations" element={<Formation />} />



                        {/* Description des solution */}

                        {/* <Route exact path="/solutions/1" element={<Description />} /> */}


                        {/* 404 */}
                    <Route exact path="*" element={<NotFound />} />
                </Routes>
            </Router>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(<Navigate />);
