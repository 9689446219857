import { NavLink,Link } from "react-router-dom"


export default function Footer(){
    return(
        <footer id="footer" className="footer" >
            <hr/>

            <div className="footer-content">
            <div className="container">
                <div className="row">

                <div className="col-lg-3 col-md-6">
                    <div className="footer-info">
                    <h3>Hi-Tech Vision</h3>
                    <p>
                        Pharmacie Cosmos <br/>
                        Douala, Cameroun<br/><br/>
                        <strong>Telephone : </strong><a href="tel:+237697999111">+ 237 697 999 111</a> / <a href="tel:+237677984698">677 984 698</a><br/>
                        <strong>Email : </strong><a href="mailto:hitechvisioncm@gmail.com">hitechvisioncm@gmail.com</a><br/>
                    </p>
                    </div>
                </div>

                <div className="col-lg-2 col-md-6 footer-links">
                    <h4>Liens Utiles</h4>
                    <ul>
                    <li><i className="bi bi-chevron-right"></i> <a href="/">Accueil</a></li>
                    <li><i className="bi bi-chevron-right"></i> <a href="/a_propos">A Propos</a></li>
                    <li><i className="bi bi-chevron-right"></i> <a href="/solutions">Solutions</a></li>
                    <li><i className="bi bi-chevron-right"></i> <a href="/realisations">Réalisations</a></li>
                    <li><i className="bi bi-chevron-right"></i> <a href="/contact">Contact</a></li>
                    </ul>
                </div>

                <div className="col-lg-3 col-md-6 footer-links">
                    <h4>Nos Services</h4>
                    <ul>
                    <li><i className="bi bi-chevron-right"></i> <a href="/site_internet">Création de Site Internet</a></li>
                    <li><i className="bi bi-chevron-right"></i> <a href="/application_web_&_mobile">Développement d'Applications</a></li>
                    <li><i className="bi bi-chevron-right"></i> <a href="/hebergement_web">Hebergement Web</a></li>
                    <li><i className="bi bi-chevron-right"></i> <a href="/marketing_digital">Marketing Digital</a></li>
                    <li><i className="bi bi-chevron-right"></i> <a href="/call_center">Call Center</a></li>
                    <li><i className="bi bi-chevron-right"></i> <a href="/conception_graphique">Conception Graphique</a></li>
                    <li><i className="bi bi-chevron-right"></i> <a href="/maintenance_informatique">Maintenance Informatique</a></li>
                    <li><i className="bi bi-chevron-right"></i> <a href="/formations">Formations</a></li>
                    </ul>
                </div>

                <div className="col-lg-4 col-md-6 footer-newsletter">
                    <h4>Notre Newsletter</h4>
                    <p>Ne manquez pas nos dernieres infos et opportunités grâce à notre newletter</p>
                    <form>
                    <input type="email" name="email"/><input type="submit" value="Souscrire"/>
                    </form>

                </div>

                </div>
            </div>
            </div>

            <div className="footer-legal text-center">
            <div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">

                <div className="d-flex flex-column align-items-center align-items-lg-start">
                <div className="copyright">
                    &copy; Copyright <strong><span><a href="/">Hi-Tech Vision</a></span></strong>. All Rights Reserved
                </div>
                </div>

                <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
                <a target="_blank" href="https://wa.link/anlmj9" className="whatsapp"><i class="bi bi-whatsapp"></i></a>
                <a target="_blank" href="https://www.facebook.com/hitechvisionofficiel/" className="facebook"><i className="bi bi-facebook"></i></a>
                {/* <a target="_blank" href="#" className="twitter"><i className="bi bi-twitter"></i></a> */}
                <a target="_blank" href="https://www.linkedin.com/company/hi-tech-vision/" className="linkedin"><i className="bi bi-linkedin"></i></a>
                </div>

            </div>
            </div>
    
        </footer>


    )
}