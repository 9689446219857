import React from 'react';
import Header from "../../components/Header";
import Althero from "../../components/Althero";
import Footer from "../../components/Footer";
import Ctf from '../../components/Ctf';
import FaqTelephonieSurIp from '../../components/FaqTelephonieSurIp';


const TelephonieIp = () => {
    return (
        <>
            <Header />
            <Althero text= 'Téléphonie sur Ip' imgUrl='assets/img/tip.jpg'/>

            <FaqTelephonieSurIp/>


            <Ctf/>
            <Footer/>

        </>
        
    );
};

export default TelephonieIp;