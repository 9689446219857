import FaqItem from "./FaqItem";

const Texte = () => {
    return (
        <p>
            Lorsque vous souhaitez installer et utiliser une caméra de surveillance, vous devez tenir compte du principe de proportionnalité.<br/>
            Le principe de proportionnalité implique :<br/>
            -   Que le traitement des images doit être approprié et nécessaire, en d'autres termes que le responsable du traitement doit s'assurer qu'il n'existe pas d'autres mesures possibles moins attentatoires à la vie privée de la personne filmée. <br/>
        </p>
        
    );
};

const Texte1=()=>{
    return(
    <p>
        La vidéosurveillance est un système de caméras et de transmission d'images, disposé dans un espace public ou privé pour le surveiller à distance ; il s'agit donc d'un type de télésurveillance. 
        Les images obtenues avec ce système, peuvent être traitées automatiquement et visionnées puis archivées ou détruites.
    </p>
    )
}
const FaqVideoSurveillance = () =>{

    return(
        <section id="faq" className="faq">
            <div className="container-fluid" data-aos="fade-up">

                <div className="row gy-4">

                    <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                        <div className="content px-xl-5">
                        <h3><strong>Questions fréquentes</strong></h3><br/>

                        </div>

                        <div className="accordion accordion-flush px-xl-5" id="faqlist">

                            <FaqItem dao="600" dbt="#faq-content-5" question="C'est quoi la vidéo surveillance ?" idFaqContent="faq-content-5" reponse={<Texte1/>} /> 
                            <FaqItem dao="200" dbt="#faq-content-1" question="Quel est le délai de conservation des images ?" idFaqContent="faq-content-1" reponse="Le délai de conservation n’excède jamais un mois, sauf si les images enregistrées peuvent contribuer à apporter la preuve d'une infraction, d'un dommage ou d'une incivilité ou peuvent permettre d'identifier l'auteur de faits, un perturbateur de l’ordre public, un témoin ou une victime."/>
                            <FaqItem dao="300" dbt="#faq-content-2" question="Qui regarde les caméra de surveillance ?" idFaqContent="faq-content-2" reponse="Qui peut consulter les images ? Seules les personnes habilitées par l'employeur, dans le cadre de leurs fonctions, peuvent visionner les images enregistrées (par exemple : le responsable de la sécurité de l'organisme)."/>
                            <FaqItem dao="400" dbt="#faq-content-3" question="Comment proceder a l'installation d'une caméra de surveillance ?" idFaqContent="faq-content-3" reponse = { <Texte />} />
                            {/* {/* <FaqItem dao="500" dbt="#faq-content-4" question="Ecran bleu ? Comment faire ?" idFaqContent="faq-content-4" reponse="Ces erreurs peuvent être liées à des problèmes matériels ou logiciels. Si vous avez ajouté du matériel à votre PC avant que l'erreur d'écran bleu ne se produise, arrêtez votre PC, retirez le matériel et redémarrez. Si vous rencontrez des difficultés à redémarrer, vous pouvez démarrer votre PC en mode sans échec."/> */}

                        </div>

                    </div>
                        <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{backgroundImage:"url('assets/img/faq.jpg')"}}>&nbsp;</div>
                </div>
            </div>
        </section>
    )
}

export default FaqVideoSurveillance;