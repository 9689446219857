import Typed from "typed.js";
import { useEffect, useRef } from "react";


function TypedComponent(props) {

    const el = useRef(null);
    const tab_content=props.typedContent;
  
    useEffect(() => {
      const typed = new Typed(el.current, {
        strings:tab_content, 
        startDelay: 300,
        typeSpeed: 70,
        backSpeed: props.backSpeed,
        backDelay: 1000,
        loop:props.loop

      });
  
      return () => {
        typed.destroy();
      };
    }, []);
  
    return <span ref={el}></span>;
  }

  export default TypedComponent;