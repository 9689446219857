import React from 'react';
import Header from "../../components/Header";
import Althero from "../../components/Althero";
import Footer from '../../components/Footer';
import Ctf from '../../components/Ctf';
import FaqApp from '../../components/FaqApp';

const Application = () => {
    return (
        <>
            <Header />
            <Althero text= 'Application Web & Mobile' imgUrl='assets/img/apps.jpg'/>

            <div id="we_Do" className="we_do">
                <div className="container">
                    <div className="row">
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <div id="hover_color" className="we_box">
                            <span>1</span>
                            <h3>Application Web</h3>
                            <p>Une application web est une application manipulable directement en ligne grâce à un navigateur web et qui ne nécessite donc pas d'installation sur les machines clientes.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div id="hover_color" className="we_box">
                            <span>2</span>
                            <h3>Application Mobile</h3>
                            <p>Il s'agit d'une application développée exclusivement pour une utilisation dédiée sur un support mobile.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div id="hover_color" className="we_box">
                            <span>3</span>
                            <h3>Application Personnalisée</h3>
                            <p>Pour un besoin particulier, nous concevons des applications web et mobiles sur mesure correspondant à des spécificités et cibles conformement à vos activités.</p>
                        </div>
                    </div>

                    </div>
                </div>
            </div>

            <FaqApp/>

            <Ctf/>
            <Footer/>
        </>
    );
};

export default Application;