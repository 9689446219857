import FaqItem from "./FaqItem";

export default function  FaqSiteInternet(){

    return(
        <section id="faq" className="faq">
            <div className="container-fluid" data-aos="fade-up">

                <div className="row gy-4">

                    <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                        <div className="content px-xl-5">
                        <h3><strong>Questions fréquentes</strong></h3><br/>

                        </div>

                        <div className="accordion accordion-flush px-xl-5" id="faqlist">

                            <FaqItem dao="200" dbt="#faq-content-1" question="Qu'est-ce qu'un site web ?" idFaqContent="faq-content-1" reponse="Un site web est un ensemble de pages web et de ressources reliées par des hyperliens, défini et accessible par une adresse web."/>
                            <FaqItem dao="300" dbt="#faq-content-2" question="Quel est l'interêt pour une entreprise d'avoir un site web ?" idFaqContent="faq-content-2" reponse="Un site internet vous permet d'inspirer confiance et de maîtriser votre communication en ligne. 56% des internautes n'ont pas confiance dans les entreprises sans site web. Un site internet permet de vous positionner comme un expert dans votre secteur d'activité et d'attirer 55% de visiteurs en plus."/>
                            <FaqItem dao="400" dbt="#faq-content-3" question="Suis-je le proprietaire de mon site ?" idFaqContent="faq-content-3" reponse="Une fois le site internet mis en ligne et l'ensemble des factures réglées, vous devenez propriétaire de la totalité du site et des composants graphiques qui le composent.Quoi qu'il se passe, vous conservez l'accès à votre site, au serveur FTP comme à la base de données."/>
                            <FaqItem dao="500" dbt="#faq-content-4" question="Quel est le prix d'un site web ?" idFaqContent="faq-content-4" reponse="Le prix d'un site internet dépend de ses fonctionnalités, de son niveau de graphisme, de l'importance de son contenu et de son besoin en référencement. Vous avez un projet web et souhaitez estimer son coût ? Faites une demande de devis dès aujourd'hui."/>
                            <FaqItem dao="600" dbt="#faq-content-5" question="Pourquoi choisir Hi-Tech Vision pour concevoir mon site web ?" idFaqContent="faq-content-5" reponse="Nos équipes travaillent d'arrache-pied pour vous fournir des sites modernes de qualités repondant aux normes ISO tout en étant à votre écoute et respectant les délais définis dans le cahier des charges."/>

                        </div>

                    </div>
                        <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{backgroundImage:"url('assets/img/faq.jpg')"}}>&nbsp;</div>
                </div>
            </div>
        </section>
    )
}