export default function PricingHebergement(){
    return(
        <section id="pricing" className="pricing">
                <div className="container" data-aos="fade-up">

                    <div className="section-header">
                    <h2  className="text-center" style={{color:'var(--primary)',fontSize:'35px'}}>Nos Packs</h2>
                    </div>
                    <br/>
                    <br/>
                    <div className="row gy-4">

                    <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="200">
                        <div className="pricing-item">

                        <div className="pricing-header">
                            <h3>Standard</h3>
                            <h4>19.900 Fcfa<span> / An</span></h4>
                        </div>

                        <ul>
                            <li><i className="bi bi-dot"></i> <span>5 Go de stockage</span></li>
                            <li><i className="bi bi-dot"></i> <span>Bande passante 25 Go</span></li>
                            <li><i className="bi bi-dot"></i> <span>15 adresses mails pro</span></li>
                            <li><i className="bi bi-dot"></i> <span>1 Site Web</span></li>
                            <li><i className="bi bi-dot"></i> <span>1 Base de données</span></li>
                            <li><i className="bi bi-dot"></i> <span>1 Domaine offert</span></li>
                            <li><i className="bi bi-dot"></i> <span>Version de Php au choix</span></li>
                            <li><i className="bi bi-dot"></i> <span>Comptes FTP illimités</span></li>
                            <li><i className="bi bi-dot"></i> <span>Certificat SSL</span></li>
                            {/* <li className="na"><i className="bi bi-x"></i> <span>Certificat SSL</span></li> */}
                        </ul>

                        <div className="text-center mt-auto">
                            <a href="#" className="buy-btn">Commander</a>
                        </div>

                        </div>
                    </div>

                    <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="400">
                        <div className="pricing-item featured">

                        <div className="pricing-header">
                            <h3>Professionnel</h3>
                            <h4>24.900 Fcfa<span> /An</span></h4>
                        </div>

                        <ul>
                            <li><i className="bi bi-dot"></i> <span>25 Go de stockage</span></li>
                            <li><i className="bi bi-dot"></i> <span>Bande passante 250 Go</span></li>
                            <li><i className="bi bi-dot"></i> <span>30 adresses mails pro</span></li>
                            <li><i className="bi bi-dot"></i> <span>5 Site Web</span></li>
                            <li><i className="bi bi-dot"></i> <span>5 Base de données</span></li>
                            <li><i className="bi bi-dot"></i> <span>1 Domaine offert</span></li>
                            <li><i className="bi bi-dot"></i> <span>Version de Php au choix</span></li>
                            <li><i className="bi bi-dot"></i> <span>Comptes FTP illimités</span></li>
                            <li><i className="bi bi-dot"></i> <span>Certificat SSL</span></li>
                        </ul>

                        <div className="text-center mt-auto">
                            <a href="#" className="buy-btn">Commander</a>
                        </div>

                        </div>
                    </div>

                    <div className="col-lg-4" data-aos="zoom-in" data-aos-delay="600">
                        <div className="pricing-item">

                            <div className="pricing-header">
                                <h3>Premium</h3>
                                <h4>34.900 Fcfa<span> / An</span></h4>
                            </div>

                        <ul>
                            <li><i className="bi bi-dot"></i> <span>50 Go de stockage</span></li>
                            <li><i className="bi bi-dot"></i> <span>Bande passante illimitée</span></li>
                            <li><i className="bi bi-dot"></i> <span>Adresses mails pro illimitées</span></li>
                            <li><i className="bi bi-dot"></i> <span>10 Site Web</span></li>
                            <li><i className="bi bi-dot"></i> <span>10 Base de données</span></li>
                            <li><i className="bi bi-dot"></i> <span>1 Domaine offert</span></li>
                            <li><i className="bi bi-dot"></i> <span>Version de Php au choix</span></li>
                            <li><i className="bi bi-dot"></i> <span>Comptes FTP illimités</span></li>
                            <li><i className="bi bi-dot"></i> <span>Certificat SSL</span></li>
                        </ul>

                            <div className="text-center mt-auto">
                                <a href="#" className="buy-btn">Commander</a>
                            </div>

                        </div>
                    </div>
  
                    </div>

                </div>
            </section>

    )
}