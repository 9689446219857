export default function FaqItem(props){
    return(
        <div className="accordion-item" data-aos="fade-up" data-aos-delay={props.dad}>
        <h3 className="accordion-header">
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={props.dbt}>
                <i className="bi bi-question-circle question-icon"></i>
                {props.question}
            </button>
        </h3>
        <div id={props.idFaqContent} className="accordion-collapse collapse" data-bs-parent="#faqlist">
            <div className="accordion-body">
                <i className="bi bi-question-circle question-icon"></i>
                {props.reponse}
            </div>
        </div>
    </div>
    )
}