import FaqItem from "./FaqItem";

export default function  FaqApp(){

    return(
        <section id="faq" className="faq">
            <div className="container-fluid" data-aos="fade-up">

                <div className="row gy-4">

                    <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                        <div className="content px-xl-5">
                        <h3><strong>Questions fréquentes</strong></h3><br/>

                        </div>

                        <div className="accordion accordion-flush px-xl-5" id="faqlist">

                            <FaqItem dao="200" dbt="#faq-content-1" question="Quel langages pour developper une application web ?" idFaqContent="faq-content-1" reponse="De nombreux langages de programmation sont disponibles afin de concevoir une application web. Nous utilisons régulierement Javascript pour la partie front-end et PHP pour le back-end"/>
                            <FaqItem dao="300" dbt="#faq-content-2" question="Quelle est la différence entre une application web et native ?" idFaqContent="faq-content-2" reponse="Une application web ou Web App sera accessible depuis tous les types de terminaux, alors que l'application native nécessitera autant de versions que de systèmes d'exploitation."/>
                            <FaqItem dao="400" dbt="#faq-content-3" question="Quelles technologies utilisez-vous pour vos applications mobiles ?" idFaqContent="faq-content-3" reponse="Pour la conception d'apps mobiles, nous utlisons des technologies telles que React Native et Flutter ce qui nous permet d'avoir une grande accéssibilité sur tout type de système d'exploitation."/>
                            {/* <FaqItem dao="500" dbt="#faq-content-4" question="" idFaqContent="faq-content-4" reponse="Le prix d'un site internet dépend de ses fonctionnalités, de son niveau de graphisme, de l'importance de son contenu et de son besoin en référencement. Vous avez un projet web et souhaitez estimer son coût ? Faites une demande de devis dès aujourd'hui."/> */}
                            <FaqItem dao="600" dbt="#faq-content-5" question="Pourquoi choisir Hi-Tech Vision pour concevoir mon application ?" idFaqContent="faq-content-5" reponse="Nos équipes travaillent d'arrache-pied pour vous fournir des applications modernes de qualités repondant aux normes ISO tout en étant à votre écoute et respectant les délais définis dans le cahier des charges."/>

                        </div>

                    </div>
                        <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{backgroundImage:"url('assets/img/faq.jpg')"}}>&nbsp;</div>
                </div>
            </div>
        </section>
    )
}