import React from "react";
import Header from '../components/Header';
import Althero from '../components/Althero';
import Footer from '../components/Footer';

import Button from  '../components/Button';

import {Link} from "react-router-dom";
import Ctf from "../components/Ctf";

const Solution = () => {
    return (
            <>
                <Header/>
                <Althero text='Nos Solutions' imgUrl='assets/img/solution.jpg' />

                <section id="services" className="services">
                    <div className="container" data-aos="fade-up">
                        <div className="row gy-5">
                            <div className="col-xl-4 col-md-6 test animate__animated animate__fadeInLeft" data-aos="zoom-in" data-aos-delay="200">
                                <div className="service-item">
                                    <div className="img">
                                        <img src="assets/img/solution2.jpg" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="details position-relative">
                                        <div className="icon">
                                        <i class='bx bxs-graduation' ></i>
                                        </div>
                                        <Link to="/solutions/esm" className="stretched-link">
                                        <h3>Easy School Manager</h3>
                                        </Link>
                                        <p>Easy School Manager en abrégé ESM est logiciel destiné à la gestion de la scolarite de different etablissement. 
                                            il permet notament l'enregistrement des etudiants et le suivie en temps réel de leur paiements et bien evidenment 
                                            de leurs solvabilités.
                                        </p>
                                        <br/>

                                        <Button link="/solutions/esm" bgColor="var(--primary)" color="#fff" text="En Savoir Plus" />
                                    </div>
                                </div>
                            </div> 

                            <div className="col-xl-4 col-md-6 animate__animated animate__fadeInDown" data-aos="zoom-in" data-aos-delay="300">
                                <div className="service-item">
                                    <div className="img">
                                        <img src="assets/img/solution2.jpg" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="details position-relative">
                                        <div className="icon">
                                        <i class='bx bxs-graduation' ></i>
                                        </div>
                                        <Link to="/solutions/vision_school" className="stretched-link">
                                        <h3>Vision School </h3>
                                        </Link>
                                        <p>Vision School est un Progiciel de gestion d'etablissements en general qui comporte en tout 3 module. 
                                            le 1er pour la gestion des emplois de temps, le 2e pour la gestion des notes et le 3e pour la gestion de la paie.
                                        </p>
                                        <br/>

                                        <Button link="/solutions/vision_school" bgColor="var(--primary)" color="#fff" text="En Savoir Plus" />

                                    </div>
                                </div>
                            </div> 

                            <div className="col-xl-4 col-md-6 animate__animated animate__fadeInRight" data-aos="zoom-in" data-aos-delay="400">
                                <div className="service-item">
                                    <div className="img">
                                        <img src="assets/img/solution2.jpg" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="details position-relative">
                                        <div className="icon">
                                        <i className="bi bi-easel"></i>
                                        </div>
                                        <Link to="/solutions/hi_stock" className="stretched-link">
                                        <h3>Hi-Stock</h3>
                                        </Link>
                                        <p>Hi-Stock est un logiciel ideale pour la gestion de stock allant de la gestion des besoins en termes de produits 
                                            jusqu'aux etats de stock, vous disposez d'un puissant atout d'aide à la decision quant l'approvisionement de votre stock.
                                            </p>
                                            <br/>
                                        <Button link="/solutions/hi_stock" bgColor="var(--primary)" color="#fff" text="En Savoir Plus" />
                                    
                                    </div>
                                </div>
                            </div> 

                            
                            <div className="col-xl-4 col-md-6 animate__animated animate__fadeInRight" data-aos="zoom-in" data-aos-delay="400">
                                <div className="service-item">
                                    <div className="img">
                                        <img src="assets/img/solution2.jpg" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="details position-relative">
                                        <div className="icon">
                                        <i className="bi bi-easel"></i>
                                        </div>
                                        <Link to="/solutions/test_generator_solution" className="stretched-link">
                                        <h3>Test Generator Solution</h3>
                                        </Link>
                                        <p>Test Generator Solution lorem Ipsum Dolor....</p>
                                        <br/>
                                        <Button link="/solutions/test_generator_solution" bgColor="var(--primary)" color="#fff" text="En Savoir Plus" />
                                    
                                    </div>
                                </div>
                            </div> 


                            <div className="col-xl-4 col-md-6 animate__animated animate__fadeIn" data-aos="zoom-in" data-aos-delay="400">
                                <div className="service-item">
                                    <div className="img">
                                        <img src="assets/img/solution2.jpg" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="details position-relative">
                                        <div className="icon">
                                        <i class='bx bxs-book' ></i>
                                        </div>
                                        <Link to="/solutions/vision_ebook" className="stretched-link">
                                        <h3>Vision E-book</h3>
                                        </Link>
                                        <p>Vision E-book lorem Ipsum Dolor....</p>
                                        <br/>
                                        <Button link="/solutions/vision_ebook" bgColor="var(--primary)" color="#fff" text="En Savoir Plus" />
                                    
                                    </div>
                                </div>
                            </div> 

                            <div className="col-xl-4 col-md-6 animate__animated animate__fadeInRight" data-aos="zoom-in" data-aos-delay="400">
                                <div className="service-item">
                                    <div className="img">
                                        <img src="assets/img/solution2.jpg" className="img-fluid" alt=""/>
                                    </div>
                                    <div className="details position-relative">
                                        <div className="icon">
                                        <i class='bx bx-cable-car'></i>
                                        </div>
                                        <Link to="/solutions/vision_travel_manager" className="stretched-link">
                                        <h3>Vision Travel Manager</h3>
                                        </Link>
                                        <p>Vision Travel Manager lorem Ipsum Dolor....</p>
                                        <br/>
                                        <Button link="/solutions/vision_travel_manager" bgColor="var(--primary)" color="#fff" text="En Savoir Plus" />
                                    
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <Ctf/>


                <Footer />
        </>
    );
};

export default Solution; 