import TypedComponent from "./TypedComponent";

import { NavLink } from "react-router-dom";

export default function Ctf(){
    return(
        <section id="cta" className="cta">
        <div className="container">
  
          <div className="row">
            <div className="col-lg-9 text-center text-lg-start">
              <h3>Passez à l'Action !</h3>
              <p>Un projet ? Besoin d'un de nos services ? Bénéficiez dès aujourd'hui d'une expertise <TypedComponent typedContent={['professionnelle','qualifiée','innovante','engagée et responsable']} backSpeed={30} loop={true}/></p>
            </div>
            <div className="col-lg-3 cta-btn-container text-center">
              <a className="cta-btn align-middle" href="/contact">Contactez-Nous</a>
            </div>
          </div>
  
        </div>
      </section>
    )
}