export default function AvantagesHebergement(){
    return(
        <section id="about" className="about" style={{paddingBottom:'0'}}>
        <div className="container">

            <div className="row">
                <div className="col-lg-6" data-aos="zoom-in">
                    <img src="assets/img/web.jpg" className="img-fluid animate__animated animate__bounceIn" alt=""/>
                </div>
                <div className="col-lg-6 d-flex flex-column justify-contents-center" data-aos="fade-left">
                    <div className="content pt-4 pt-lg-0">
                    <h3><strong>Hébergement Web au Cameroun</strong></h3><br/>
                    <p className="fst-italic line">
                        <strong><a href="/">Hi-Tech Vision</a></strong> est le partenaire d'<strong>hébergement web</strong> idéal pour particuliers et entreprises de part la rapidité de nos serveurs et un ensemble d'avantages tels que :
                    </p>
                    <ul className="animate__animated animate__fadeInRight">
                        <li><i className="bi bi-check-circle" style={{color:'var(--primary)'}}></i> Haute Disponibilité 99.99%</li>
                        <li><i className="bi bi-check-circle" style={{color:'var(--primary)'}}></i> Sécurité et Fiabilité Optimale</li>
                        <li><i className="bi bi-check-circle" style={{color:'var(--primary)'}}></i> Des Disques Haute Performence (SSD)</li>
                        <li><i className="bi bi-check-circle" style={{color:'var(--primary)'}}></i> Assistance et Support Technique 24h/24</li>
                        <li><i className="bi bi-check-circle" style={{color:'var(--primary)'}}></i> CMS Inclus (WordPress, Drupal...)</li>
                        <li><i className="bi bi-check-circle" style={{color:'var(--primary)'}}></i> Nom de Domaine Gratuit</li>
                        <li><i className="bi bi-check-circle" style={{color:'var(--primary)'}}></i> Interface Cpanel</li>
                        <li><i className="bi bi-check-circle" style={{color:'var(--primary)'}}></i> Domaine Inclus (.com, .net, .org, .cm)</li>
                        <li><i className="bi bi-check-circle" style={{color:'var(--primary)'}}></i> Système de Sauvegarde (Back-up)</li>
                    </ul>

                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}