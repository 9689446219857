import React from "react";

import Header from '../components/Header';
import Althero from '../components/Althero';
import Footer from '../components/Footer';

const Contact = () => {
    return (
        <div>
                <Header/>
                <Althero text='Contact' imgUrl='assets/img/img6.jpg' />
                <section id="contact" class="contact">
                        
                        <div class="container">

                            <div class="row gy-5 gx-lg-5">

                            <div class="col-lg-4">

                                <div class="info">
                                    <h3>Contactez-nous</h3>
                                    <p>Pour en savoir plus ou pour un besoin. </p>

                                    <div class="info-item d-flex">
                                        <i class="bi bi-geo-alt flex-shrink-0"></i>
                                        <div>
                                        <h4>Localisation : </h4>
                                        <p>Pharmacie Cosmos Douala, Cameroun</p>
                                        </div>
                                    </div>

                                    <div class="info-item d-flex">
                                        <i class="bi bi-envelope flex-shrink-0"></i>
                                        <div>
                                        <h4>Email : </h4>
                                        <p><a href="mailto:hitechvisioncm@gmail.com">hitechvisioncm@gmail.com</a></p>
                                        </div>
                                    </div>

                                    <div class="info-item d-flex">
                                        <i class="bi bi-phone flex-shrink-0"></i>
                                        <div>
                                        <h4>Telephone : </h4>
                                        <p> <a href="tel:+237697999111">+ 237 697 999 111</a> / <a href="tel:+237677984698">677 984 698</a></p>
                                        </div>
                                    </div>

                                    <div class="info-item d-flex">
                                        <i class="bx bxl-whatsapp flex-shrink-0"></i>
                                        <div>
                                            <h4>Whatsapp : </h4>
                                            <p> <a  rel="noreferrer" target="_blank" href="https://wa.link/anlmj9">+ 237 692 719 637</a></p>
                                            <img style={{width:'125px'}} src="assets/img/wa.link_anlmj9.png" alt="QrCode Whatsapp"/>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-8">
                                <form class="php-email-form">
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                        <input type="text" name="name" class="form-control" id="name" placeholder="Votre Nom" required />
                                        </div>
                                        <div class="col-md-6 form-group mt-3 mt-md-0">
                                        <input type="email" class="form-control" name="email" id="email" placeholder="Votre Email" required />
                                        </div>
                                    </div>
                                    <div class="form-group mt-3">
                                        <input type="text" class="form-control" name="sujet" id="sujet" placeholder="Objet" required />
                                    </div>
                                    <div class="form-group mt-3">
                                        <textarea class="form-control" name="message" placeholder="Message" required></textarea>
                                    </div>
                                    <div class="my-3">
                                        <div class="loading">Loading</div>
                                        <div class="error-message"></div>
                                        <div class="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div class="text-center"><button type="submit">Envoyer</button></div>
                                </form>
                            </div>

                            </div>

                        </div>

                        <br />
                        <br />

                        <div class="map">
                            <iframe title="map" src="https://maps.google.com/maps?q=hi-tech%20visio&t=&z=17&ie=UTF8&iwloc=&output=embed" frameborder="0" allowfullscreen></iframe>
                        </div>
                    </section>
                <Footer />
        </div>
    );
};

export default Contact;