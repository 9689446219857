
export default function Clients(){
    return(
        <section id="clients" className="clients">

            

            <div className="container">

                {/* <div className="section-title"> */}
                <h2 style={ {color:'var(--primary)'}} className="text-center">Ils Nous Ont Fait Confiance</h2>
                <br/>
                <p>Plusieurs projets pour différentes structures et particuliers ont déjà bénéficié de notre expertise. Nous pouvons mentionner entre autres :</p>
                {/* </div> */}

                <div className="clients-slider swiper">
                <div className="swiper-wrapper aligh-items-center">
                    <div><img src="assets/img/c1.jpg" className="img-fluid" alt=""/></div>
                    <div><img src="assets/img/c2.jpg" className="img-fluid" alt=""/></div>
                    <div><img src="assets/img/c3.jpg" className="img-fluid" alt=""/></div>
                    <div><img src="assets/img/c4.jpg" className="img-fluid" alt=""/></div>
                    <div><img src="assets/img/c5.jpg" className="img-fluid" alt=""/></div>
                    <div><img src="assets/img/c6.png" className="img-fluid" alt=""/></div>
                    <div><img src="assets/img/c7.png" className="img-fluid" alt=""/></div>
                    <div><img src="assets/img/c8.jpg" className="img-fluid" alt=""/></div>
                </div>
                <div className="swiper-pagination"></div>
                </div>

            </div>
        </section>
        
        
    )
}