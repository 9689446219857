import React from "react";
import Header from '../components/Header';
import Althero from '../components/Althero';
import Footer from '../components/Footer';
import Valeurs from "../components/Valeurs";
import AproposContent from "../components/AproposContent";


class Apropos extends React.Component{
    render(){
        return(
            <>
                <Header/>
                <Althero imgUrl='assets/img/img3.jpg' text='A Propos' />
                <Valeurs/>
                <AproposContent/>
                <Footer />
            </>
        )
    }
}

export default Apropos;