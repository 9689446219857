export default function Vps(){
    return(
        <section id="vps" className="vps">
            <div className="container" data-aos="zoom-out">

                <div className="row g-5">

                <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                    <h3>Vous avez besoin d'un <em>VPS</em> ?</h3>
                    <p>Un serveur dédié virtuel, en anglais l'appellation commerciale est virtual private server ou virtual dedicated server ; est une méthode de partitionnement d'un serveur en plusieurs serveurs virtuels indépendants qui ont chacun les caractéristiques d'un serveur dédié, en utilisant des techniques de virtualisation.</p>
                    <a className="vps-btn align-self-start" href="/lancer_votre_projet">Demander un Devis</a>
                </div>

                <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
                    <div className="img">
                    <img src="assets/img/vps.jpg" alt="" className="img-fluid"/>
                    </div>
                </div>

                </div>

            </div>
        </section>
    )
}