
import Header from '../components/Header';
import Hero from '../components/Hero';
import About from '../components/About'
import Valeurs from '../components/Valeurs';
import Services from '../components/Services';
import Ctf from '../components/Ctf';
import Footer from '../components/Footer';

function App() {

	return (
		<>
			<Header />
			<Hero />
			<Valeurs />
			<main id='main'>
				<About/>
				<Services/>
				<Ctf/>
				<Footer/>
			</main>
			
		</>
	)
}

export default App;
