import React from 'react';
import Header from "../../components/Header";
import Althero from "../../components/Althero";
import FaqSiteInternet from "../../components/FaqSiteInternet";
import Ctf from "../../components/Ctf";
import Footer from "../../components/Footer";


const SiteInternet = () => {
    return (
        <>
            <Header />
            <Althero text= 'Site internet' imgUrl='assets/img/site_internet.jpg'/>

            <div id="we_Do" className="we_do">
                <div className="container">
                    <div className="row">
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <div id="hover_color" className="we_box">
                            <span>1</span>
                            <h3>Site Vitrine</h3>
                            <p>Il s'agit d'un site Web qui présente les produits ou les services d'une organisation, dans le but d'attirer simplement l'attention et d'éveiller l'intérêt des internautes.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div id="hover_color" className="we_box">
                            <span>2</span>
                            <h3>Site E-Commerce</h3>
                            <p>C'est un site web destiné au commerce électronique, il permettra l'achat et la vente de vos produits et services sur Internet.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div id="hover_color" className="we_box">
                            <span>3</span>
                            <h3>Site Personnalisé</h3>
                            <p>Pour un besoin particulier, obtenez un site web sur mesure correspondant à des spécificités et cibles conformement à vos activités.</p>
                        </div>
                    </div>

                    </div>
                </div>
            </div>

            <FaqSiteInternet/>
            <Ctf/>
            <Footer/>

        </>
        
    );
};

export default SiteInternet;