import React from 'react';
import Header from '../../components/Header';
import Althero from '../../components/Althero';
import Ctf from '../../components/Ctf';
import Footer from '../../components/Footer';
import FaqCallCenter from '../../components/FaqCallCenter';

export default function CallCenter() {
  return (
    <>
    
    <Header/>
    <Althero text="Call Center" imgUrl="assets/img/call_center.jpg"/>
    <div id="we_Do" className="we_do">
      <div className="container">
          <div className="row">
          </div>
          <div className="row">
          <div className="col-md-3">
              <div id="hover_color" className="we_box">
                  <span>1</span>
                  <h3>Emission d'appel</h3>
                  <p>L'émission d'appels est surtout essentielle dans l'aspect commercial. Et permet pour beaucoup d’entreprises d’augmenter leur chiffre d’affaire.</p>
              </div>
          </div>
          <div className="col-md-3">
              <div id="hover_color" className="we_box">
                  <span>2</span>
                  <h3>Réception d'appel</h3>
                  <p>La réception d'appels devient de plus en plus un outil d'upselling ou de crossselling. Transformer un client mécontent en un client qui achète encore plus.</p>
              </div>
          </div>
          <div className="col-md-3">
              <div id="hover_color" className="we_box">
                  <span>3</span>
                  <h3>Emailing</h3>
                  <p>L'emailing regroupe tous les secteurs de l'émission et de la réception d'appels. A l'heure du numérique les clients veulent éviter l'attente et les numéros surtaxés.</p>
              </div>
          </div>
          <div className="col-md-3">
              <div id="hover_color" className="we_box">
                  <span>4</span>
                  <h3>Tchat</h3>
                  <p>A l'heure du numérique le Tchat est maintenant un outil commun. Que ce soit en prévente, durant la vente ou après la vente, la présence d'un Tchat permet au client d'être rassuré.</p>
              </div>
          </div>

          </div>
      </div>
    </div>
    <FaqCallCenter/>
    <Ctf/>
    <Footer/>
    
    </>


  )
}
