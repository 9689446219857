import React from 'react';
import Header from "../../components/Header";
import Althero from "../../components/Althero";
import FaqVideoSurveillance from "../../components/FaqVideoSurveillance";
import Ctf from "../../components/Ctf";
import Footer from "../../components/Footer";



const VideoSurveillance = () => {
    return (
        <>
            <Header />
            <Althero text= 'Vidéo Surveillance' imgUrl='assets/img/vs.jpg'/>
            <FaqVideoSurveillance/>
            <Ctf/>
            <Footer/>

        </>
        
    );
};

export default VideoSurveillance;