import FaqItem from "./FaqItem";

export default function  FaqCallCenter(){

    const Faq1Content=()=>{
        return(
            <p>
                Un <strong>call center</strong> désigne donc un service de télécommunication centralisé qui permet aux entreprises de répondre aux besoins de leurs prospects et clients. Il y a les appels entrants pour répondre à leurs différentes problématiques et les appels sortants, par exemple, pour mettre en place des actions de <strong>télémarketing</strong>. Nous pouvons vous accompagner dans l'<strong>installation et la configuration de centres d'appels</strong> pour votre entreprise.
            </p>
        )
    }

    return(
        <section id="faq" className="faq">
            <div className="container-fluid" data-aos="fade-up">

                <div className="row gy-4">

                    <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                        <div className="content px-xl-5">
                        <h3><strong>Questions fréquentes</strong></h3><br/>

                        </div>

                        <div className="accordion accordion-flush px-xl-5" id="faqlist">
                            <FaqItem dao="200" dbt="#faq-content-1" question="Quel est le rôle d'un Call Center ?" idFaqContent="faq-content-1" reponse={<Faq1Content/>}/>
                            <FaqItem dao="300" dbt="#faq-content-2" question="Pourquoi une entreprise doit-elle avoir un Call Center ?" idFaqContent="faq-content-2" reponse="Les sociétés ont généralement recours aux centres d'appels pour gérer l'afflux des appels téléphoniques. En effet, de plus en plus d'entreprises s'entretiennent avec non seulement des clients nationaux, mais aussi des clients issus des 4 coins du globe. Les call center ont l'avantage d'utiliser des logiciels spécifiques qui permettent de gérer plusieurs appels en même temps. Grâce à cette technologie innovante, les entreprises n'ont plus besoin de recruter de nouveaux employés pour la gestion des appels entrants."/>
                            <FaqItem dao="400" dbt="#faq-content-3" question="Comment convaincre un client au téléphone ?" idFaqContent="faq-content-3" reponse="Pour cela, vous devez être bien à l'écoute. Prendre des notes durant votre appel peut donc être judicieux. Vous aurez sûrement à traiter les objections de votre interlocuteur. Acceptez-les, c'est une preuve d'intérêt : votre client cherche à savoir si ce que vous lui proposez lui est intéressant."/>
                            <FaqItem dao="500" dbt="#faq-content-4" question="Quel est le bon rythme au téléphone ?" idFaqContent="faq-content-4" reponse="Il faut donc parler de manière intelligible pour être bien compris par le locuteur. Une variation du flux et du tempo est ainsi essentielle pour rendre l'échange dynamique. Une info au passage, le rythme approprié pour une conversation au téléphone est de 120 à 150 mots par minute."/>
                            {/* <FaqItem dao="600" dbt="#faq-content-5" question="" idFaqContent="faq-content-5" reponse=""/> */}

                        </div>

                    </div>
                        <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{backgroundImage:"url('assets/img/faq2.jpg')"}} >&nbsp;</div>
                </div>
            </div>
        </section>
    )
}