import React from 'react';
import {NavLink} from "react-router-dom";
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const Description = (props) => {
    return (
        <>
            <Header/>
            <main id="main">


            <section id="breadcrumbs" className="breadcrumbs">
                <div className="container">

                    <ol>
                        <li><NavLink to="/solutions">Solutions</NavLink></li>
                        <li>Detais du Projet</li>
                    </ol>
                    <h2>{props.name}</h2>

                </div>
            </section> 

            <section id="portfolio-details" className="portfolio-details">
                <div className="container">

                    <div className="row gy-4">

                        <div className="col-lg-8">
                            <div className="portfolio-details-slider swiper">
                                <div className="swiper-wrapper align-items-center">

                                    <div className="swiper-slide animate__animated animate__fadeInLeft">
                                        <img className='boite' src={props.imgUrl} alt="Boite"/>
                                    </div>

                                </div>
                                {/* <div className="swiper-pagination"></div> */}
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="portfolio-info">
                                <h3>Informations sur la solution :</h3>
                                <ul>
                                    <li><strong>Catégorie: </strong>{props.category}</li>
                                    {/* <li><strong>Client</strong> : Institut Universitaire de l'Estuaire (IUEs)</li> */}
                                    <li><strong>Date derniere mise à jour: </strong>{props.dateMaj}</li>
                                    <li><strong>Url du projet</strong> : <a target="_blank"  rel="noreferrer" href={props.solutionUrl}>{props.solutionUrl}</a></li>
                                </ul>
                            </div>
                            <div className="portfolio-description">
                                <h2>Description</h2>
                                <p>
                                    {props.description}
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

            </main> 
            <Footer />
        </>
    
    );
};

export default Description;