import React from 'react';


export default function RealisationItem(props) {
    return (
    <>
        <div className="col-md-4">
            <div className="work-box">
            <a target="_blank" rel="noreferrer" href={props.projectUrl} data-gallery="portfolioGallery" className="portfolio-lightbox">
                <div className="work-img">
                <img src={props.imgUrl} alt="" className="img-fluid"/>
                </div>
            </a>
            <div className="work-content" >
                <div className="row">
                <div className="col-sm-8">
                    <h2 className="w-title">{props.title}</h2>
                    <div className="w-more">
                    <span className="w-ctegory">{props.category}</span> / <span className="w-date">{props.createdAt}</span>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="w-like">
                    <a target="__blank" href={props.projectUrl}> <span className="bx bx-chevrons-right"></span></a>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
    
    </>
    )
}
