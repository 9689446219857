import FaqItem from "./FaqItem";

export default function  FaqTelephonieSurIp(){

    return(
        <section id="faq" className="faq">
            <div className="container-fluid" data-aos="fade-up">

                <div className="row gy-4">

                    <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                        <div className="content px-xl-5">
                        <h3><strong>Questions fréquentes</strong></h3><br/>

                        </div>

                        <div className="accordion accordion-flush px-xl-5" id="faqlist">

                            <FaqItem dao="200" dbt="#faq-content-1" question="C'est quoi la voix sur Ip ?" idFaqContent="faq-content-1" reponse="La voix sur IP, ou « VoIP » pour « Voice over IP », est une technologie informatique qui permet de transmettre la voix sur des réseaux compatibles IP, via Internet ou des réseaux privés ou publics, qu'ils soient filaires ou non."/>
                            <FaqItem dao="300" dbt="#faq-content-2" question="Quelle est la différence entre une téléphonie classique et une sur Ip ?" idFaqContent="faq-content-2" reponse="La téléphonie IP vous permet de brancher tous vos appareils sur le même réseau, alors que la téléphonie analogique nécessite une connexion différente pour chaque appareil."/>
                            {/* <FaqItem dao="400" dbt="#faq-content-3" question="Quelles technologies utilisez-vous pour vos applications mobiles ?" idFaqContent="faq-content-3" reponse="Pour la conception d'apps mobiles, nous utlisons des technologies telles que React Native et Flutter ce qui nous permet d'avoir une grande accéssibilité sur tout type de système d'exploitation."/> */}
                            <FaqItem dao="500" dbt="#faq-content-4" question="Pourquoi passer à la téléphonie sur Ip pour mon entreprise ?" idFaqContent="faq-content-4" reponse="Elle simplifie la communication entre les différents services et facilite l'administration. Ensuite, comme cette technologie ne demande pas beaucoup de bande passante, les entreprises peuvent relier plusieurs sites entre eux. Enfin, la téléphonie IP apporte plus de souplesse à l'entreprise."/>
                            {/* <FaqItem dao="600" dbt="#faq-content-5" question="Pourquoi choisir Hi-Tech Vision pour concevoir mon application ?" idFaqContent="faq-content-5" reponse="Nos équipes travaillent d'arrache-pied pour vous fournir des applications modernes de qualités repondant aux normes ISO tout en étant à votre écoute et respectant les délais définis dans le cahier des charges."/> */}

                        </div>

                    </div>
                        <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{backgroundImage:"url('assets/img/faq.jpg')"}}>&nbsp;</div>
                </div>
            </div>
        </section>
    )
}