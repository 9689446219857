import Header from "../../components/Header";
import Althero from "../../components/Althero";
import Ctf from "../../components/Ctf";
import Footer from "../../components/Footer";
import FaqMaintenance from "../../components/FaqMaintenance";

export default function MaintenanceInfo(){
    return(
        <>
            <Header/>
            <Althero imgUrl="assets/img/maintenance.jpg" text="Maintenance Informatique"/>
            
            <div id="we_Do" className="we_do">
                <div className="container">
                    <div className="row">
                    </div>
                    <div className="row">
                    <div className="col-md-4">
                        <div id="hover_color" className="we_box">
                            <span>1</span>
                            <h3>Maintenance préventive</h3>
                            <p>
                                Elle a pour mission d’empêcher l’apparition de problèmes informatiques et de virus. Ce type de maintenance informatique sert à sécuriser le système et optimiser son efficacité.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div id="hover_color" className="we_box">
                            <span>2</span>
                            <h3>Maintenance corrective</h3>
                            <p>Pour ce type de maintenance, il permet de remettre en état un équipement ou un logiciel pour qu’il puisse remplir convenablement sa fonction. Il s’agit, ainsi, d’une opération ponctuelle.</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div id="hover_color" className="we_box">
                            <span>3</span>
                            <h3>Maintenance évolutive</h3>
                            <p>En effet, comme son nom l'indique, la maintenance informatique évolutive a pour but de faire évoluer le système informatique en fonctions des avancées technologiques.</p>
                        </div>
                    </div>

                    </div>
                </div>
            </div>

            <FaqMaintenance/>
            <Ctf/>
            <Footer/>
        
        </>
    )
}