import React from "react";
import {Link, useHref} from "react-router-dom";


class Button extends React.Component{
    constructor(props){
        super(props);
        this.state={
                color:this.props.color,
                backgroundColor:this.props.bgColor,
                link:this.props.link
        };
        
        this.mouseOver=this.mouseOver.bind(this);
        this.mouseLeave=this.mouseLeave.bind(this);
        this.navigateTo=this.navigateTo.bind(this);
    }

    mouseOver(){
        this.setState(()=>({
            color:'var(--primary)',
            backgroundColor:'#fff'
        }));
    }

    mouseLeave(){
        this.setState(()=>({
            color:'#fff',
            backgroundColor:'var(--primary)'
        }));
    }

    navigateTo(){
        window.location.pathname=this.props.link;
    }


    render(){
        return (
                <button id="btnComp" onClick={this.navigateTo} onMouseOver={this.mouseOver} onMouseLeave={this.mouseLeave}  style={ {border:'1px solid var(--primary)',padding:'6px 12px',color:this.state.color,backgroundColor:this.state.backgroundColor} }> {this.props.text} </button>
            )
    }

}
export default Button;