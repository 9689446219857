export default function Stats_clients(){
    return(
        <section class="why_section layout_padding">
        <div class="container">
        <div class="custom_heading-container">
            <h2>
                Activité et Satisfaction ... 
            </h2>
        </div>
        <div class="content-container">
            {/* <p>
                De part la multitude de projets sur lesquels nous avons été emmené à travaillé, nos clients nous font généralement des retours caactérisés par ces chiffres : 
            </p> */}
            <br/>
            <div class="row">
            <div class="col-md-3 col-sm-6">
                <div class="img-box">
                <img src="assets/img/smiley.png" alt=""/>
                </div>
                <div class="detail-box">
                <h3> 
                    99%
                </h3>
                <h6>
                    Satisfaction
                </h6>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="img-box">
                <img src="assets/img/monitor.png" alt=""/>
                </div>
                <div class="detail-box">
                <h3>
                    600+
                </h3>
                <h6>
                    Projets Réalisés
                </h6>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="img-box">
                <img src="assets/img/multiple-users-silhouette.png" alt=""/>
                </div>
                <div class="detail-box">
                <h3>
                    400+
                </h3>
                <h6>
                    Clients
                </h6>
                </div>
            </div>
            <div class="col-md-3 col-sm-6">
                <div class="img-box">
                <img src="assets/img/bar-chart.png" alt=""/>
                </div>
                <div class="detail-box">
                <h3>
                    150+
                </h3>
                <h6>
                    Activité quotidienne
                </h6>
                </div>
            </div>
            </div>
        </div>
        </div>
  </section>
    )
}