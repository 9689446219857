import React from "react";
import {NavLink} from "react-router-dom";
import Clients from "./Clients";
import Stats_clients from "./Stats_clients";

class AproposContent extends React.Component{
    render(){
        return(

            <div>
                <section id="a_propos" className="a_propos">
                <div className="container" data-aos="fade-up">

                    <div className="section-header">
                    <h2 className="text-center">Pourquoi Nous ?</h2>
                    <br/>
                    <p className="line">Travailler avec nous, c'est travailler avec une équipe dynamique, professionnelle, intègre et responsable à votre écoute et dont l'objectif reste par dessus tout, de vous accompagner dans la réalisation de vos projets depuis leur conception, leur implémentation jusqu'à leur déploiement et maintenance.</p>
                    </div>

                    <div className="row g-4 g-lg-5" data-aos="fade-up" data-aos-delay="200">

                    <div className="col-lg-5"> 
                        <div className="about-img">
                        <img src="assets/img/img4.jpg" className="img-fluid" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <h3 className="pt-0 pt-lg-5"><NavLink to="/" className="pt-0 pt-lg-5"><strong>Hi-Tech Vision</strong></NavLink> en tant que :</h3>

                        <ul className="nav nav-pills mb-3">
                        <li><a className="nav-link active" data-bs-toggle="pill" href="#tab1"><strong>Agence Digitale</strong></a></li>
                        <li><a className="nav-link" data-bs-toggle="pill" href="#tab2"><strong>Agence Marketing</strong></a></li>
                        <li><a className="nav-link" data-bs-toggle="pill" href="#tab3"><strong>Hébergeur Web</strong></a></li>
                        </ul>

                        <div className="tab-content">

                        <div className="tab-pane fade show active animate__animated animate__fadeInRight" id="tab1">

                            <p className="fst-italic line">Une agence digitale est une experte en communication web et permet à une entreprise, quel que soit son secteur d'activité, de gagner en visibilité par un meilleur positionnement dans les moteurs de recherche. Nous vous accompagnons dans la réalisation de vos projets grâce au digital</p>
                            
                        </div>

                        <div className="tab-pane fade show animate__animated animate__fadeInRight" id="tab2">

                            <p className="fst-italic line">Une agence de webmarketing est spécialisée dans la promotion de contenus digitaux pour développer la visibilité d’une entreprise ou d’une institution. Elle propose ainsi diverses prestations en actionnant divers leviers sur différents supports : moteurs de recherche, réseaux sociaux, emailing, affiliation … en fonction de la stratégie de marketing digital de son client.</p>


                        </div>

                        <div className="tab-pane fade show animate__animated animate__fadeInRight" id="tab3">

                            <p className="fst-italic line">Nous fournissons l'hébergement sur Internet de systèmes informatiques divers, tels que sites web, stockage d'information, messagerie électronique, etc. à des personnes, associations, projets ou autres entités qui ne désirent pas le faire par leurs propres moyens. Nous disposons grâce à nos partenaires d'un parc de serveurs informatiques qui bénéficie 24 heures sur 24 d'une connexion à Internet de haut débit pour offrir un accès rapide aux clients ou à tous les internautes.</p>

                        </div>

                        </div>

                    </div>

                    </div>

                </div>
                </section>
                
                <Clients/>
                <Stats_clients/>
            </div>

        )
    }
}


export default AproposContent;