import FaqItem from "./FaqItem";

export default function  FaqMaintenance(){

    return(
        <section id="faq" className="faq">
            <div className="container-fluid" data-aos="fade-up">

                <div className="row gy-4">

                    <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                        <div className="content px-xl-5">
                        <h3><strong>Questions fréquentes</strong></h3><br/>

                        </div>

                        <div className="accordion accordion-flush px-xl-5" id="faqlist">

                            <FaqItem dao="200" dbt="#faq-content-1" question="C'est quoi la maintenance informatique ?" idFaqContent="faq-content-1" reponse="En entreprise ou pour le compte d'un fournisseur de services, elle s'occupe de la réparation, de l'entretien du matériel et également de l'installation de divers logiciels ou équipements informatiques."/>
                            <FaqItem dao="300" dbt="#faq-content-2" question="Qu'est-ce qu'un virus informatique ?" idFaqContent="faq-content-2" reponse="Un virus est un programme informatique malveillant qui a pour objectif de perturber le fonctionnement normal d'un appareil informatique voire de dérober des informations personnelles qu'il contient."/>
                            <FaqItem dao="400" dbt="#faq-content-3" question="Comment protéger votre ordinateur contre les virus ?" idFaqContent="faq-content-3" reponse="Utiliser une application anti-programme malveillant : l'installation d'une application anti-programme malveillant et sa mise à jour peuvent aider à protéger votre PC contre les virus et autres programmes malveillants."/>
                            <FaqItem dao="500" dbt="#faq-content-4" question="Ecran bleu ? Comment faire ?" idFaqContent="faq-content-4" reponse="Ces erreurs peuvent être liées à des problèmes matériels ou logiciels. Si vous avez ajouté du matériel à votre PC avant que l'erreur d'écran bleu ne se produise, arrêtez votre PC, retirez le matériel et redémarrez. Si vous rencontrez des difficultés à redémarrer, vous pouvez démarrer votre PC en mode sans échec."/>
                            <FaqItem dao="600" dbt="#faq-content-5" question="Pourquoi choisir Hi-Tech Vision pour ma maintenance informatique ?" idFaqContent="faq-content-5" reponse="De part le panel de maintenaciers qualifiés et engagés que nous disposons, nous mettons davantages l'accent sur le facteur humain en mettant la satisfaction de nos client au centre de nos différentes activités."/>

                        </div>

                    </div>
                        <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{backgroundImage:"url('assets/img/faq.jpg')"}}>&nbsp;</div>
                </div>
            </div>
        </section>
    )
}