import FaqItem from "./FaqItem";

export default function  FaqAdministrationReseau(){

    return(
        <section id="faq" className="faq">
            <div className="container-fluid" data-aos="fade-up">

                <div className="row gy-4">

                    <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                        <div className="content px-xl-5">
                        <h3><strong>Questions fréquentes</strong></h3><br/>

                        </div>

                        <div className="accordion accordion-flush px-xl-5" id="faqlist">

                            <FaqItem dao="200" dbt="#faq-content-1" question="C'est quoi l'administration réseau ?" idFaqContent="faq-content-1" reponse="La définition exacte de «l'administration du réseau» est difficile à cerner. Dans une entreprise plus grande, elle serait plus souvent strictement liée au réseau réel. Plus précisément, cela comprend la gestion et la maintenance des commutateurs, des routeurs, des pare-feu, des passerelles VPN, etc. Dans les petites entreprises, l'administrateur du réseau est souvent un métier polyvalent et impliqué dans la configuration des bases de données, l'installation, la maintenance et la mise à niveau. des logiciels, la gestion des comptes d'utilisateurs et des groupes de sécurité, la prise en charge des postes de travail et parfois même le développement de logiciels de base."/>
                            <FaqItem dao="300" dbt="#faq-content-2" question="Quel est la différence entre un commutateur et un routeur ?" idFaqContent="faq-content-2" reponse="Globalement, un commutateur réseau déplace les données entre deux périphériques réseau, tandis qu'un routeur achemine généralement les données entre deux réseaux connectés."/>
                            <FaqItem dao="400" dbt="#faq-content-3" question="C'est quoi la virtualisation ?" idFaqContent="faq-content-3" reponse="La virtualisation est une technologie que vous pouvez utiliser pour créer des représentations virtuelles de serveurs, de stockage, de réseaux et d'autres machines physiques. Le logiciel virtuel imite les fonctions du matériel physique pour exécuter plusieurs machines virtuelles sur une seule machine physique."/>
                            <FaqItem dao="500" dbt="#faq-content-4" question="Pourquoi virtualiser ?" idFaqContent="faq-content-4" reponse="La virtualisation accroît la sécurité de votre infrastructure informatique. La virtualisation est un excellent moyen d'éviter la propagation des virus et autres malwares. En effet, si un ordinateur virtuel est infecté, le fonctionnement en vase clos permet d'éviter d'infecter l'ensemble de votre réseau."/>
                            {/* <FaqItem dao="600" dbt="#faq-content-5" question="Pourquoi choisir Hi-Tech Vision pour concevoir mon site web ?" idFaqContent="faq-content-5" reponse="Nos équipes travaillent d'arrache-pied pour vous fournir des sites modernes de qualités repondant aux normes ISO tout en étant à votre écoute et respectant les délais définis dans le cahier des charges."/> */}

                        </div>

                    </div>
                        <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" style={{backgroundImage:"url('assets/img/faq.jpg')"}}>&nbsp;</div>
                </div>
            </div>
        </section>
    )
}