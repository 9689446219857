import Header from "../../components/Header";
import AltHero from "../../components/Althero";
import AvantagesHebergement from "../../components/AvantagesHeberment";
import PricingHebergement from "../../components/PricingHebergement";
import Vps from "../../components/Vps";
import Footer from "../../components/Footer";
import Ctf from "../../components/Ctf";

export default function HebergementWeb(){
    return(
        <>
            <Header/>
            <AltHero text="Hébergement Web" imgUrl='assets/img/img7.jpg'/>
            <AvantagesHebergement/>
            <section id="vps" className="vps">
                <div className="container" data-aos="zoom-out">

                    <div className="row g-5">

                        <div className="col-lg-8 col-md-6 content d-flex flex-column justify-content-center order-last order-md-first">
                            <h3>Rechercher un nom de domaine</h3>
                            <p>Vous pouvez rechercher la disponibilité de votre nom domaine</p>
                            <form action="https://domainserver.camertrainings.com/cart.php?a=add&domain=register" method="POST">
                                <input className="form-control" type="text" placeholder="Entrez votre nom de domaine ici" name="query" size="20" />
                                <input className="vps-btn align-self-start" style={{border:'none'}} type="submit" value="Rechercher" />
                            </form>
                        </div>

                        <div className="col-lg-4 col-md-6 order-first order-md-last d-flex align-items-center">
                            <div className="img">
                            <img src="assets/img/domaine.jpg" alt="" className="img-fluid"/>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <PricingHebergement/>
            <Vps/>
            <Ctf/>
            <Footer/>
        </>
    )
}