import React from 'react';
import Header from "../../components/Header";
import Althero from "../../components/Althero";
import Ctf from "../../components/Ctf";
import Footer from "../../components/Footer";
import FaqAdministrationReseau from '../../components/FaqAdministrationReseau';


const AdministrationReseau = () => {
    return (
        <>
            <Header />
            <Althero text= 'Administration Réseau' imgUrl='assets/img/ar.jpg'/>

            <FaqAdministrationReseau/>
            <Ctf/>

            <Footer/>

        </>
        
    );
};

export default AdministrationReseau;