import React from "react";
import TypedComponent from "./TypedComponent";
import { NavLink} from "react-router-dom";

class Hero extends React.Component{

    render(){

      return(
            <section id="hero">
            <div id="heroCarousel" data-bs-interval="5000" className="carousel slide carousel-fade" data-bs-ride="carousel">

              <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

              <div className="carousel-inner" role="listbox">

                <div className="carousel-item active animate__animated animate__fadeIn" style={ {backgroundImage: 'url(assets/img/car2.jpg)'}}> 
                  <div className="carousel-container">
                    <div className="container">
                      <div>
                        <h2 className="animate__animated animate__bounce "><span>Hi-Tech Vision</span></h2>
                      </div>
                      <p className="animate__animated animate__fadeInUp">Votre <TypedComponent typedContent={ ['agence digitale...','hébergeur web...','solution informatique...'] } backSpeed={45} loop={true}/></p>
                      <br/>
                      <p className='animate__animated animate__fadeInDown'>
                        Nous sommes une agence digitale  spécialisée dans la conception et mise en place de solution informatique situer à quelque mètres de la pharmacie Cosmos (entre BP-CITE et Ndokotti)  dans la ville de Douala.
                      </p>
                      <NavLink to="/a_propos" className="btn-get-started animate__animated animate__fadeInUp scrollto">En Savoir Plus</NavLink>
                    </div>
                  </div>
                </div>

              </div>

              {/* <NavLink className="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
                <span className="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
              </NavLink>

              <NavLink className="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
                <span className="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
              </NavLink> */}

            </div>
          </section>
      )
        
    }
}

export default Hero;