import Header from '../components/Header';
import Althero from '../components/Althero';
import Ctf from '../components/Ctf';
import Footer from '../components/Footer';
import RealisationItem from '../components/RealisationItem';

export default function Realisations() {

  return (
    <>
      <Header/>
      <Althero text='Réalisations' imgUrl='assets/img/realisations.jpg'/>

      <section id="work" className="portfolio-mf sect-pt4 route">

        <div className="container">
          
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                {/* <h3 className="title-a">
                  Portfolio
                </h3> */}
                <p className="subtitle-a">
                  Durant nos années d'activité, nous avons réalisé une multitude de projets tous les uns aussi passionants que les autres et de divers horizons dont en voici certains: 
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>


          <br/>


          <div className="row">

                <RealisationItem title="UPAE" category="Site Internet" createdAt="Date de Réalisation" imgUrl="assets/img/upae.png" projectUrl="https://upae-univ.com/" />
                <RealisationItem title="ENTREPRISE CM" category="Site Internet" createdAt="Date de Réalisation" imgUrl="assets/img/entreprisecm.png" projectUrl="https://entreprisecm.com/" />
                <RealisationItem title="BIOTANCY" category="Site Internet" createdAt="Date de Réalisation" imgUrl="assets/img/biotancy.png" projectUrl="https://biotancy.fr/" />
                <RealisationItem title="CCIE" category="Site Internet" createdAt="Date de Réalisation" imgUrl="assets/img/ccie.png" projectUrl="http://www.ccie.cm" />
                <RealisationItem title="FALCON SHOPS" category="Site Internet" createdAt="Date de Réalisation" imgUrl="assets/img/falconshops.png" projectUrl="https://falconshops.net/" />
                <RealisationItem title="E-CAMPUS ISSI" category="E-Learning" createdAt="Date de Réalisation" imgUrl="assets/img/issi.png" projectUrl="https://ecampus-issi.com/"/>
                
          </div>

        </div>
      </section>


      <Ctf/>
      <Footer/>
    
    
    </>
  )
}
