import React from 'react';
import Header from "../../components/Header";
import Althero from "../../components/Althero";
import Ctf from "../../components/Ctf";
import Footer from "../../components/Footer";
import FormationItem from '../../components/FormationItem';



const Formation = () => {
  
    return (
        <>
            <Header />
            <Althero text= 'Formations' imgUrl='assets/img/formations.jpg'/>


            <section id="features" className="features">
              <div className="features__wrapper">

                <div className="section-header">
                  <div className="section-header__title  section-header__title--features text-center">Le savoir est une arme...</div>
                  <div className="section-header__subtitle text-center">A <strong><a href='/'>Hi-Tech Vision</a></strong>, nous disposons d'un ensemble de <strong>formations</strong> aussi bien en ligne qu'en présentiel dans de multiples domaines du <strong>digital</strong>.</div>
                </div>

                <div className="features__elements-wrapper">

                  <FormationItem imgUrl="assets/img/excel.png" title="Microsoft Excel" description="Microsoft Excel est un logiciel tableur de la suite bureautique Microsoft Office développé et distribué par l'éditeur Microsoft."/>
                  <FormationItem imgUrl="assets/img/wordpress.png" title="WordPress" description="WordPress est un système de gestion de contenu gratuit, libre et open-source."/>
                  <FormationItem imgUrl="assets/img/powerpoint.png" title="Microsoft Powerpoint" description="Microsoft PowerPoint est un logiciel de présentation édité par Microsoft. Il fait partie de la suite Microsoft Office."/>
                  <FormationItem imgUrl="assets/img/word.png" title="Microsoft Word" description="Microsoft Word est un logiciel de traitement de texte publié par Microsoft"/>
                  <FormationItem imgUrl="assets/img/photoshop.png" title="Adobe Photoshop" description="Adobe Photoshop est un logiciel de retouche, de traitement et de dessin assisté par ordinateur édité par Adobe."/>


                </div>

              </div>

            </section>

            <Ctf/>

            <Footer/>

        </>
        
    );
};

export default Formation;