import Althero from "../../components/Althero";
import Ctf from "../../components/Ctf";
import FaqMarketingDigital from "../../components/FaqMarketingDigital";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

export default function MarketingDigital(){
    return(
        <>
            <Header/>
            <Althero imgUrl="assets/img/markdi.jpg" text="Marketing Digital"/>
            
            <div id="we_Do" className="we_do">
                <div className="container">
                    <div className="row">
                    </div>
                    <div className="row">
                    <div className="col">
                        <div id="hover_color" className="we_box">
                            <span>1</span>
                            <h3>E-réputation</h3>
                            <p>Mettre en place un community management et une stratégie digitale globale pour véhiculer l'image positive de votre marque ou entreprise sur les réseaux sociaux.</p>
                        </div>
                    </div>
                    <div className="col">
                        <div id="hover_color" className="we_box">
                            <span>2</span>
                            <h3>Animation de communauté</h3>
                            <p>Développer la notoriété de votre marque, de votre produit, de votre projet en fédérant et en animant les communautés ciblées via les réseaux sociaux et les canaux digitaux.</p>
                        </div>
                    </div>
                    <div className="col">
                        <div id="hover_color" className="we_box">
                            <span>3</span>
                            <h3>Campagne publicitaire social media</h3>
                            <p>Mettre en place et gérer les campagnes publicitaires social Ads, gérer leur optimisation en tenant compte de vos cibles, de vos objectifs et du budget alloué.</p>
                        </div>
                    </div>

                    <div className="col">
                        <div id="hover_color" className="we_box">
                            <span>4</span>
                            <h3>Veille réseaux</h3>
                            <p>Utiliser les différentes plateformes de la veille technologique pour identifier et anticiper les différentes évolutions à mettre en place en marketing digital.</p>
                        </div>
                    </div>

                    </div>
                </div>
            </div>
            <section id="about" className="about">
                <div className="container">

                    <div className="section-title">
                    <h2 style={{ color:'var(--primary'}}>Cest quoi le <strong>Marketing Digital</strong> ?</h2>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iure repudiandae esse deleniti labore laboriosam quasi sit quidem facere aliquid repellat perspiciatis explicabo libero provident, delectus distinctio. Provident obcaecati optio quis.</p> */}
                    </div>

                    <div className="row">
                    <div className="col-lg-6 order-1 order-lg-2">
                        <img src={'assets/img/markdi2.jpg'} className="img-fluid animate__animated animate__jackInTheBox" alt="Marketing Digital"/>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                        <h5><a href="/"><strong>Hi-Tech Vision</strong></a> est <strong>agence marketing</strong> qui vous accompagne dans la mise en place de votre stratégie de marketing digital.<strong></strong></h5>
                        <br/>
                        <p className="fst-italic line">
                            <strong>Le webmarketing</strong> ou <strong>Marketing digital</strong> a toujours eu pour fonction de mettre les entreprises en contact avec leur audience au bon endroit et au bon moment. Aujourd'hui, il faut aller à la rencontre du consommateur là où il passe le plus clair de son temps : sur internet tout ceci par un ensemble de stratégies, mécanismes et savoir faire que nous mettons à la disposition de nos clients.
                        </p>
                    </div>
                    </div>

                </div>
            </section>

            <FaqMarketingDigital/>

            <Ctf/>
            <Footer/>
        
        </>
    )
}