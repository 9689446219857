import React from "react";

class About extends React.Component{
    render(){
        return(
            <section id="about" className="about">
                <div className="container">

                    <div className="section-title">
                        <h2 style={{ color:'var(--primary'}}>Qui sommes-nous ?</h2>
                    </div>

                    <div className="row">
                    <div className="col-lg-6 order-1 order-lg-2">
                        <img src={'assets/img/car1.jpg'} className="img-fluid" alt="A Propos De Nous"/>
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content">
                        <h4 className="text-center"><strong><a href="/">Hi-Tech Vision</a></strong></h4><h5> est une <strong> agence digitale</strong> spécialisée dans la <strong>prestation de services informatiques et solutions numériques</strong>.</h5>
                        <br/>
                        <p className="fst-italic line">
                            Notre but est de réaliser des produits et services informatiques pour apporter de la productivité et de l'éfficience dans la réalisation des activités de la clientèle, apportant ainsi l'assurance de la satisfaction clientèle. 
                            Nous avons la ferme volonté de confirmer notre rôle d'initiateur de tendance technologique, porté par l'excellence et le professionnalisme de nos équipes, des hommes et des femmes dotés d'expertise, d'une grande intégrité et d'un sens aigu de l'innovation.
                        </p>
                    </div>
                    </div>

                </div>
            </section>
        )
    }
}

export default About;