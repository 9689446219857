import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

import Althero from '../components/Althero';


const Projet = () => {
    return (
        <>
            <Header/>
            <Althero text="Demander Un Devis" imgUrl="assets/img/devis.jpg"/>
            {/* <section id="breadcrumbs" classNameName="breadcrumbs">
                <div classNameName="container">

                    <ol>
                        <li><NavLink to="/">Accueil</NavLink></li>
                        <li>Projet</li>
                    </ol>
                    <h3>DEMANDER UN DEVIS</h3><br/> 
                    
                </div>
            </section>  */}
            <section id="contact" className="contact">
                        
                        <div className="container">

                            <div className="row gy-5 gx-lg-5">

                            <div className="col-lg-4">

                                <div className="info">
                                    <h3>Contactez-Nous</h3>
                                    <p>Pour en savoir plus ou pour un besoin. </p>

                                    <div className="info-item d-flex">
                                        <i className="bi bi-geo-alt flex-shrink-0"></i>
                                        <div>
                                        <h4>Localisation : </h4>
                                        <p>Pharmacie Cosmos Douala, Cameroun</p>
                                        </div>
                                    </div>

                                    <div className="info-item d-flex">
                                        <i className="bi bi-envelope flex-shrink-0"></i>
                                        <div>
                                        <h4>Email : </h4>
                                        <p><a href="mailto:hitechvisioncm@gmail.com">hitechvisioncm@gmail.com</a></p>
                                        </div>
                                    </div>

                                    <div className="info-item d-flex">
                                        <i className="bi bi-phone flex-shrink-0"></i>
                                        <div>
                                        <h4>Telephone : </h4>
                                        <p><a href="tel:+237697999111">+ 237 697 999 111</a> / <a href="tel:+237677984698">677 984 698</a></p>
                                        </div>
                                    </div>
                                    <div class="info-item d-flex">
                                        <i class="bx bxl-whatsapp flex-shrink-0"></i>
                                        <div>
                                            <h4>Whatsapp : </h4>
                                            <p>  <a rel="noreferrer" target="_blank" href="https://wa.link/anlmj9">+ 237 692 719 637</a></p>
                                            <img alt="QrCode Whatsapp" style={{width:'125px'}} src="assets/img/wa.link_anlmj9.png"/>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-lg-8">
                            <p style={{fontSize:'20px',color:'var(--primary)'}}><strong>Vos Informations</strong></p>

                                <form className="php-email-form">
                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <input type="text" name="name" className="form-control" id="name" placeholder="Nom Complet" required />
                                        </div>
                                        <div className="col-md-6 form-group mt-3 mt-md-0">
                                            <input type="text" className="form-control" name="entreprise" id="entreprise" placeholder="Entreprise ou Organisation" required />
                                        </div>
                                    </div>
                                    
                                    <br/>

                                    <div className="row">
                                        <div className="col-md-6 form-group">
                                            <input type="email" name="email" className="form-control" id="email" placeholder="Email" required />
                                        </div>
                                        <div className="col-md-6 form-group mt-3 mt-md-0">
                                            <input type="text" pattern="\+[0-9]{3}-[0-9]{3}-[0-9]{3}-[0-9]{3}" className="form-control" name="telephone" id="telephone" placeholder="Numero de téléphone +237 697-999-111" required />
                                            <small>Format : +xxx-xxx-xxx-xxx</small>
                                        </div>
                                    </div>

                                    <div className="form-group mt-3">
                                        <p style={{fontSize:'20px',color:'var(--primary)'}}><strong>Service Souhaité</strong></p>
                                        <div className='d-flex projet'>
                                            <div>
                                                <input type="checkbox" name='site_web'/> Site Web
                                            </div>
                                            <div>
                                                <input type="checkbox"/> Applicaton Web / Mobile
                                            </div>
                                            <div>
                                                <input type="checkbox"/> Hébergement Web
                                            </div>
                                        </div>
                                        <br/>
                                        <div className='d-flex projet'>
                                            <div>
                                                <input type="checkbox"/> Marketing Digital
                                            </div>
                                            <div>
                                                <input type="checkbox"/> Conception Graphique
                                            </div>
                                            <div>
                                                <input type="checkbox"/> Maintenance Informatique
                                            </div>
                                        </div>
                                        <br/>
                                        <div className='d-flex projet'>
                                            <div>
                                                <input type="checkbox"/> Téléphonie Sur Ip
                                            </div>
                                            <div>
                                                <input type="checkbox"/> Vidéo Surveillance
                                            </div>
                                            <div>
                                                <input type="checkbox"/> Administration Réseau
                                            </div>
                                        </div>
                                        <br/>
                                        <div className='d-flex projet'>
                                            <div>
                                                <input type="checkbox"/> Formations
                                            </div>
                                            <div>
                                                <input type="checkbox"/> ERP / Logiciel de Gestion
                                            </div>
                                            <div>
                                                <input type="checkbox"/> Call Center/Centre d'appel
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="form-group mt-3">
                                    <p style={{fontSize:'20px',color:'var(--primary)'}}><strong>Description de Votre Projet</strong></p>
                                        <textarea className="form-control" name="message" placeholder="Description de la demande" required></textarea>
                                    </div>
                                    <div className="my-3">
                                        {/* <div className="loading">Loading</div> */}
                                        {/* <div className="error-message"></div> */}
                                        <div className="sent-message">Your message has been sent. Thank you!</div>
                                    </div>
                                    <div className="text-center"><button type="submit">Soumettre</button></div>
                                </form>
                            </div>

                            </div>

                        </div>

                    </section>
            <Footer/>

        </>
    );
};

export default Projet;