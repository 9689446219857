import React from 'react';


// const handleClick=()=>{
//     alert('Détails sur la formation');
// }

export default function FormationItem(props) {
    return (
        <div className="features__element">
            <div className="features__image" style={{backgroundImage:'url('+props.imgUrl+')'}}>&nbsp;</div>
            <div className="features__text">
            <div className="features__title">{props.title}</div>
            <div className="features__description">{props.description}</div>
            </div>
        </div>
    )
}
